import { useWebVersionStore } from '@/shared/config/stores';

import type { ResponseInterceptor } from './types';

export const interceptorWebAppVersion: ResponseInterceptor = {
  onFulfilled: (config) => {
    const latestVersion = config.headers['x-webapp-latest-ver'];

    if (latestVersion) {
      const { setVersion } = useWebVersionStore();
      setVersion(config.headers['x-webapp-latest-ver']);
    }

    return config;
  },
};
