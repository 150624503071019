import type { MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { readableServiceType } from '@/entities/carrier';
import { canReadCarriers } from '@/entities/session';
import type { Carrier, GetCarriersParams } from '@/shared/api/generated-api/carriers/data-contracts.ts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { createBeforeEnterRouteAccessCheck } from '@/shared/lib/utils';

export const route: RouteRecordRaw = {
  path: 'carriers',
  name: RouteName.CARRIERS,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/Carriers.vue');
  },
  beforeEnter: createBeforeEnterRouteAccessCheck(canReadCarriers),
};

export const tableColumns: MTableColumn<Carrier>[] = [
  {
    label: 'Перевозчик',
    value: 'carrier',
    customRender: (data) => data.companyName,
  },
  {
    label: 'ИНН',
    value: 'inn',
  },
  {
    label: 'Город (Юридический адрес)',
    value: 'city',
    customRender: (data) => data.legalAddress?.city || data.legalAddress?.settlement || '',
  },
  {
    label: 'Вид услуг',
    value: 'serviceType',
    customRender: (data) => data.serviceTypes?.map((s) => readableServiceType(s)).join(' ,') ?? '',
  },
  {
    label: 'Статус',
    value: 'status',
  },
];

export const getDefaultFilter = (): GetCarriersParams => ({
  companyName: '',
  inn: '',
  serviceTypes: [],
  carrierStatuses: [],
});

export const CARRIERS_PER_PAGE_SIZE = 50;
