import type { App } from 'vue';

import { withMetrika } from './plugin-metrika';
import { withRouter } from './plugin-router';
import { withSentry } from './plugin-sentry';
import { withStore } from './plugin-store';
import { withUiKit } from './plugin-ui-kit';

const plugins = [withStore, withRouter, withUiKit, withSentry, withMetrika];

export const withPlugins = (app: App): App => {
  plugins.forEach((plugin): void => {
    plugin(app);
  });

  return app;
};
