/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
  /** Код ошибки */
  code: number;
  /** Сообщение об ошибке */
  message: string;
  /** Детали ошибки */
  details?: string;
}

export interface LogisticPoint {
  /**
   * ID логистического пункта
   * @format uuid
   * @example fa8e65e2-11de-4d74-801b-99069ed764d0
   */
  id: string;
  /**
   * Короткое название логистического пункта
   * @example КЗН-ФФ-001
   */
  shortName: string;
}

/** Точка маршрута */
export interface WaybillPoint {
  /** Логистическая точка */
  logisticPoint: LogisticPoint;
}

/** Маршрут для генерации накладной */
export interface WaybillRoute {
  /** Точка отправления */
  departurePoint: WaybillPoint;
  /** Точка прибытия */
  arrivalPoint: WaybillPoint;
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface Shipment {
  /**
   * ID отгрузки
   * @format int64
   * @example 25573
   */
  id: number;
}

export interface ShipmentRoute {
  shipment: Shipment;
  departure: LogisticPoint;
  arrival: LogisticPoint;
}

export type TripSchedulePointsResponse = ShipmentRoute[];
