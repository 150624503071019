/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HealthCheckResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const HealthCheckRoutesRegExp = {
  checkHealth: {
    regExp: '^/__health$',
    method: 'GET',
  },
};

export class HealthCheck<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags HealthCheck
   * @name CheckHealth
   * @summary Проверка состояния сервера
   * @request GET:/__health
   * @secure
   */
  checkHealth = (params: RequestParams = {}) =>
    this.request<HealthCheckResponse, any>({
      path: `/__health`,
      method: 'GET',
      secure: true,
      headers: {
        'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0',
        Pragma: 'no-cache',
        Expires: '0',
      },
      ...params,
    });
}
