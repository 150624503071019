import { formatDate, readableEnum } from '@mm-frontend/mithril-ui-kit';
import type { MDropItem, MOptionItem, MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { getCarrierById } from '@/entities/carrier';
import { getLogisticPointByShortName } from '@/entities/logistic-points/api';
import { readableTransportationType } from '@/entities/transportation-orders';
import { readableTripEvent } from '@/entities/trip';
import type { LogisticPoint } from '@/shared/api/generated-api/logistic-points/data-contracts';
import type { Trip, TripFilter } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import {
  ProblemType,
  TripDriverStatus,
  TripSortDirectionEnum,
  TripSortFieldEnum,
  TripStatus,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { removeTimezoneOffset, removeUTC, replaceCommaInDate } from '@/shared/lib/utils';

import { isCompleted } from './lib';
import { TripTableAction, type TripLocalFilter } from './types';

export const maxTripNumber = 1_999_999_999;

export const route: RouteRecordRaw = {
  path: 'monitoring',
  name: RouteName.MONITORING,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/Monitoring.vue');
  },
};
export const tripsPageSize = 20;

export const tripActionMenuDropList: MDropItem<TripTableAction>[] = [
  {
    text: 'Сформировать ТрН',
    action: TripTableAction.CREATE_DOCUMENT,
  },
];

const defaultFilter: TripFilter = {
  tripNumber: undefined,
  tripStatus: [],
  vehicleRegNumber: '',
  expectedEventEventType: [],
  schedulePlanFirstDepartureAtFrom: '',
  schedulePlanFirstDepartureAtTo: '',
  scheduleDepartureCity: '',
  scheduleDestinationCity: '',
  scheduleTransitCity: '',
  transportationOrderCarrierId: undefined,
  transportationOrderTripName: '',
  scheduleIsRoundTrip: undefined,
  scheduleDepartureLogisticPointCode: '',
  scheduleDestinationLogisticPointCode: '',
  scheduleTransitLogisticPointCode: '',
  transportationOrderType: [],
  isOwnCarrier: undefined,
  isLateProblem: undefined,
  isDeliveryProblem: undefined,
  tripDriverStatus: undefined,
};

export const getDefaultFilter = (): TripFilter => {
  const filter = structuredClone(defaultFilter);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  filter.schedulePlanFirstDepartureAtFrom = removeTimezoneOffset(today).toISOString();
  return filter;
};

export const createFilterFromLocalFilter = (localFilter: TripLocalFilter): TripFilter => {
  return {
    tripNumber: localFilter.number,
    tripStatus: localFilter.status,
    vehicleRegNumber: localFilter.registrationNumber,
    expectedEventEventType: localFilter.waitingEvent,
    schedulePlanFirstDepartureAtFrom: localFilter.startDate
      ? removeTimezoneOffset(localFilter.startDate).toISOString()
      : undefined,
    schedulePlanFirstDepartureAtTo: localFilter.endDate
      ? removeTimezoneOffset(localFilter.endDate).toISOString()
      : undefined,
    scheduleDepartureCity: localFilter.departureCity,
    scheduleDestinationCity: localFilter.destinationCity,
    scheduleTransitCity: localFilter.transitCity,
    transportationOrderCarrierId: localFilter.carrier?.id,
    scheduleIsRoundTrip: localFilter.isRoundTrip === undefined ? undefined : Boolean(localFilter.isRoundTrip),
    scheduleDepartureLogisticPointCode: localFilter.departureLogisticPoint?.shortName,
    scheduleDestinationLogisticPointCode: localFilter.destinationLogisticPoint?.shortName,
    scheduleTransitLogisticPointCode: localFilter.transitLogisticPoint?.shortName,
    transportationOrderType: localFilter.type,
    transportationOrderTripName: localFilter.transportationOrderTripName,
    isOwnCarrier:
      localFilter.isOwnCarrier === undefined || localFilter.isOwnCarrier.length > 1
        ? undefined
        : Boolean(localFilter.isOwnCarrier[0]),
    isLateProblem: localFilter.problemType?.includes(ProblemType.PROBLEM_TYPE_DELAY) ? true : undefined,
    isDeliveryProblem: localFilter.problemType?.includes(ProblemType.PROBLEM_TYPE_DELIVERY_PROBLEM) ? true : undefined,
    tripDriverStatus: localFilter.tripDriverStatus,
  };
};

const getPointByShortName = (logisticPointCode?: string): Promise<LogisticPoint | null> =>
  logisticPointCode ? getLogisticPointByShortName(logisticPointCode) : Promise.resolve(null);

export const createLocalFilterFromFilter = async (filter: TripFilter): Promise<TripLocalFilter> => {
  const [departureLogisticPoint, destinationLogisticPoint, transitLogisticPoint, carrier] = await Promise.all([
    filter.scheduleDepartureLogisticPointCode
      ? getPointByShortName(filter.scheduleDepartureLogisticPointCode)
      : Promise.resolve(undefined),
    filter.scheduleDestinationLogisticPointCode
      ? getPointByShortName(filter.scheduleDestinationLogisticPointCode)
      : Promise.resolve(undefined),
    filter.scheduleTransitLogisticPointCode
      ? getPointByShortName(filter.scheduleTransitLogisticPointCode)
      : Promise.resolve(undefined),
    filter.transportationOrderCarrierId
      ? getCarrierById(filter.transportationOrderCarrierId)
      : Promise.resolve(undefined),
  ]);
  const startDate = removeUTC(filter.schedulePlanFirstDepartureAtFrom);
  const endDate = removeUTC(filter.schedulePlanFirstDepartureAtTo);
  return {
    startDate: startDate ? new Date(startDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
    departureCity: filter.scheduleDepartureCity,
    destinationCity: filter.scheduleDestinationCity,
    transitCity: filter.scheduleTransitCity,
    departureLogisticPoint: departureLogisticPoint || undefined,
    destinationLogisticPoint: destinationLogisticPoint || undefined,
    transitLogisticPoint: transitLogisticPoint || undefined,
    status: filter.tripStatus,
    number: filter.tripNumber,
    isRoundTrip: filter.scheduleIsRoundTrip === undefined ? undefined : filter.scheduleIsRoundTrip ? 1 : 0,
    carrier: carrier || undefined,
    type: filter.transportationOrderType,
    registrationNumber: filter.vehicleRegNumber,
    waitingEvent: filter.expectedEventEventType,
    transportationOrderTripName: filter.transportationOrderTripName,
    isOwnCarrier: filter.isOwnCarrier === undefined ? undefined : filter.isOwnCarrier ? [1] : [0],
    problemType: filter.isLateProblem ? [ProblemType.PROBLEM_TYPE_DELAY] : undefined,
    tripDriverStatus: filter.tripDriverStatus,
  };
};

export const tripIsRoundOptions: MOptionItem[] = [
  {
    title: 'Да',
    id: 1,
  },
  {
    title: 'Нет',
    id: 0,
  },
];

export const emptyValue = '—';

export const tableColumns: MTableColumn<Trip>[] = [
  {
    label: '',
    value: 'problematic',
  },
  {
    label: '№ рейса',
    value: 'number',
  },
  {
    label: 'Водитель',
    value: 'driver',
  },
  {
    label: 'Наименование',
    value: 'tripName',
    isSortable: true,
  },
  {
    label: 'Ожидаемое событие',
    value: 'event',
    customRender: (row) =>
      row.expectedEvent && !isCompleted(row) ? readableTripEvent(row.expectedEvent.eventType) : emptyValue,
  },
  {
    label: 'Лог. пункт события',
    value: 'eventLogisticPoint',
    customRender: (row) =>
      row.expectedEvent?.schedulePoint.logisticPoint?.shortName && !isCompleted(row)
        ? row.expectedEvent.schedulePoint.logisticPoint.shortName
        : emptyValue,
  },
  {
    label: 'Осталось до плана',
    value: 'timeLeft',
    isSortable: true,
  },
  {
    label: 'Плановое время',
    value: 'timePlanned',
    customRender: (row) =>
      isCompleted(row)
        ? emptyValue
        : replaceCommaInDate(formatDate(removeUTC(row.expectedEvent?.planTimeAt), 'digital', '—')),
  },
  {
    label: 'Действие по событию',
    value: 'eventAction',
  },
  {
    label: 'Утилизация',
    value: 'utilization',
    customRender: (row) => (row.utilization ? `${row.utilization}%` : emptyValue),
  },
  {
    label: 'ТС',
    value: 'vehicle',
  },
  {
    label: 'Перевозчик',
    value: 'carrier',
    customRender: (row) => row.transportationOrder?.carrier?.companyName ?? emptyValue,
  },
  {
    label: 'Статус рейса',
    value: 'status',
  },
  {
    label: 'Начальный пункт',
    value: 'departurePoint',
    customRender: (row) => row.transportationOrder?.schedule.firstSchedulePoint.logisticPoint?.shortName ?? emptyValue,
  },
  {
    label: 'План. дата отправления НП',
    value: 'departurePlannedDate',
    customRender: (row) =>
      replaceCommaInDate(formatDate(removeUTC(row.transportationOrder?.schedule.planFirstDepartureAt), 'digital', '—')),
    isSortable: true,
  },
  {
    label: 'Факт. дата отправления НП',
    value: 'departureFactDate',
    customRender: (row) => replaceCommaInDate(formatDate(removeUTC(row.factFirstDepartureAt), 'digital', '—')),
    isSortable: true,
  },
  {
    label: 'План. дата прибытия КП',
    value: 'arrivalPlannedDate',
    customRender: (row) =>
      replaceCommaInDate(formatDate(removeUTC(row.transportationOrder?.schedule.planLastArrivalAt), 'digital', '—')),
    isSortable: true,
  },
  {
    label: 'Факт. дата прибытия КП',
    value: 'arrivalFactDate',
    customRender: (row) => replaceCommaInDate(formatDate(removeUTC(row.factLastArrivalAt), 'digital', '—')),
    isSortable: true,
  },
  {
    label: 'Вид перевозки',
    value: 'transportationType',
    customRender: (row) =>
      row.transportationOrder ? readableTransportationType(row.transportationOrder.type) : emptyValue,
  },
  {
    label: 'Круговой рейс',
    value: 'isRoundTrip',
    customRender: (row) => (row.transportationOrder?.schedule.isRoundTrip ? 'Да' : 'Нет'),
  },
  {
    label: 'Плановая куб. ТС',
    value: 'vehicleCapacity',
    customRender: (row) =>
      row.transportationOrder?.cargoVolume ? `${row.transportationOrder.cargoVolume} м3` : emptyValue,
    isSortable: true,
  },
  {
    label: '',
    value: 'action',
  },
];

export const tripSortFieldMap: Record<string, TripSortFieldEnum> = {
  timeLeft: TripSortFieldEnum.TripEventPlanTimeAt,
  departurePlannedDate: TripSortFieldEnum.TransportationOrderPlanFirstDepartureAt,
  departureFactDate: TripSortFieldEnum.TripFactFirstDepartureAt,
  arrivalPlannedDate: TripSortFieldEnum.TransportationOrderPlanLastArrivalAt,
  arrivalFactDate: TripSortFieldEnum.TripFactLastArrivalAt,
  vehicleCapacity: TripSortFieldEnum.TransportationOrderCargoVolume,
  tripName: TripSortFieldEnum.TransportationOrderTripName,
};

export const tripSortDirectionMap: Record<string, TripSortDirectionEnum> = {
  asc: TripSortDirectionEnum.Asc,
  desc: TripSortDirectionEnum.Desc,
  default: TripSortDirectionEnum.Asc,
};

export const readableFilterTripDriverStatus = readableEnum<TripDriverStatus, string>({
  [TripDriverStatus.TRIP_DRIVER_STATUS_ASSIGNED]: 'Ожидает подтверждения',
  [TripDriverStatus.TRIP_DRIVER_STATUS_ACCEPTED]: 'Подтвержден',
  [TripDriverStatus.TRIP_DRIVER_STATUS_DECLINED]: 'Отклонен',
});

export const tripStatusesEnabledForSetUtilization: ReadonlySet<TripStatus> = new Set([
  TripStatus.TRIP_STATUS_AT_LOG_POINT,
  TripStatus.TRIP_STATUS_ON_THE_WAY,
  TripStatus.TRIP_STATUS_COMPLETED,
]);

export const filterTripDriverStatusOptions: MOptionItem[] = Object.values(TripDriverStatus).map(
  (tripEvent: TripDriverStatus) => ({
    title: readableFilterTripDriverStatus(tripEvent),
    id: tripEvent,
  }),
);
