/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { DeletedSchedulePoint, GenericBadRequestError, GenericError, HttpMethod } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const SchedulePointsRoutesRegExp = {
  deleteSchedulePoint: {
    regExp: '^\\/api\\/v1\\/schedule-points\\/\\d+$',
    method: HttpMethod.DELETE,
  },
};

export class SchedulePoints<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Удалить точку маршрута
   *
   * @tags Schedule Points
   * @name DeleteSchedulePoint
   * @summary Удаление точки маршрута
   * @request DELETE:/api/v1/schedule-points/{schedule_point_id}
   */
  deleteSchedulePoint = (schedulePointId: string, data: DeletedSchedulePoint, params: RequestParams = {}) =>
    this.request<any, GenericBadRequestError | GenericError>({
      path: `/api/v1/schedule-points/${schedulePointId}`,
      method: 'DELETE',
      body: data,
      ...params,
    });
}
