import { storeToRefs } from 'pinia';
import type { App } from 'vue';
import { watch } from 'vue';
import { initYandexMetrika, useYandexMetrika } from 'yandex-metrika-vue3';

import { YM_ID } from '@/shared/config/metrika.ts';
import { useUserStore } from '@/shared/config/stores';
import { env } from '@/shared/env';

export const withMetrika = (app: App): void => {
  if (env.ENVIRONMENT !== 'prod') {
    return;
  }

  app.use(initYandexMetrika, {
    id: YM_ID,
    router: app.config.globalProperties.$router,
    env: process.env.NODE_ENV || 'development',
    scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
    options: { accurateTrackBounce: true, clickmap: true, trackLinks: true, webvisor: true },
  });

  const { userInfo } = storeToRefs(useUserStore());
  watch(
    userInfo,
    (newUserInfo) => {
      const yaMetrikaObject = useYandexMetrika();
      yaMetrikaObject.userParams({
        UserID: newUserInfo.id || undefined,
        Username: `${newUserInfo.firstName} ${newUserInfo.lastName}`.trim() || undefined,
      });
    },
    { immediate: true },
  );
};
