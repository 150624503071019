import { defineStore } from 'pinia';
import type { IBrowser, IDevice, IEngine, IOS } from 'ua-parser-js';

export interface State {
  fingerprint: string;
  browser?: IBrowser;
  device?: IDevice;
  engine?: IEngine;
  os?: IOS;
}

export const useDeviceInfoStore = defineStore('device-info', {
  state: (): State => ({
    fingerprint: '',
    browser: undefined,
    device: undefined,
    engine: undefined,
    os: undefined,
  }),
  actions: {
    setFingerprint(fingerprint: string) {
      this.fingerprint = fingerprint;
    },
    setBrowser(browser: IBrowser) {
      this.browser = browser;
    },
    setDevice(device: IDevice) {
      this.device = device;
    },
    setEngine(engine: IEngine) {
      this.engine = engine;
    },
    setOS(os: IOS) {
      this.os = os;
    },
  },
});
