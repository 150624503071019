/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenericBadRequestError,
  GenericError,
  GetTransportationOrdersParams,
  HttpMethod,
  Pagination,
  Schedule,
  TransportationOrder,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export const TransportationOrdersRoutesRegExp = {
  getTransportationOrders: {
    regExp: '^\\/api\\/v1\\/transportation-orders$',
    method: HttpMethod.GET,
  },
  createTransportationOrder: {
    regExp: '^\\/api\\/v1\\/transportation-orders$',
    method: HttpMethod.POST,
  },
  createTransportationOrdersByFile: {
    regExp: '^\\/api\\/v1\\/transportation-orders\\/orders-file$',
    method: HttpMethod.POST,
  },
  getTransportationOrder: {
    regExp: '^\\/api\\/v1\\/transportation-orders\\/\\d+$',
    method: HttpMethod.GET,
  },
  updateTransportationOrder: {
    regExp: '^\\/api\\/v1\\/transportation-orders\\/\\d+$',
    method: HttpMethod.PUT,
  },
  deleteTransportationOrder: {
    regExp: '^\\/api\\/v1\\/transportation-orders\\/\\d+$',
    method: HttpMethod.DELETE,
  },
  approveTransportationOrder: {
    regExp: '^\\/api\\/v1\\/transportation-orders\\/\\d+\\/status\\/approved$',
    method: HttpMethod.PATCH,
  },
  cancelTransportationOrder: {
    regExp: '^\\/api\\/v1\\/transportation-orders\\/\\d+\\/status\\/canceled$',
    method: HttpMethod.PATCH,
  },
  makeTransportationOrderCarrierSelection: {
    regExp: '^\\/api\\/v1\\/transportation-orders\\/\\d+\\/status\\/carrier-selection$',
    method: HttpMethod.PATCH,
  },
  createTransportationOrderRoute: {
    regExp: '^\\/api\\/v1\\/transportation-orders\\/route$',
    method: HttpMethod.POST,
  },
};

export class TransportationOrders<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Получить список заказов с фильтрацией и пагинацией
   *
   * @tags Transportation Orders
   * @name GetTransportationOrders
   * @summary Получение списка заказов
   * @request GET:/api/v1/transportation-orders
   */
  getTransportationOrders = (query: GetTransportationOrdersParams, params: RequestParams = {}) =>
    this.request<
      {
        list: TransportationOrder[];
        /** Постраничная навигация */
        pagination: Pagination;
      },
      GenericBadRequestError | GenericError
    >({
      path: `/api/v1/transportation-orders`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * @description Создать заказ
   *
   * @tags Transportation Orders
   * @name CreateTransportationOrder
   * @summary Создание заказа
   * @request POST:/api/v1/transportation-orders
   */
  createTransportationOrder = (data: TransportationOrder, params: RequestParams = {}) =>
    this.request<TransportationOrder, GenericBadRequestError | GenericError>({
      path: `/api/v1/transportation-orders`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Создать заказы из excel файла
   *
   * @tags Transportation Orders
   * @name CreateTransportationOrdersByFile
   * @summary Создание заказов из файла
   * @request POST:/api/v1/transportation-orders/orders-file
   */
  createTransportationOrdersByFile = (
    data: {
      /** @format binary */
      file?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<
      {
        /**
         * Количество созданных заказов
         * @min 0
         * @example 5
         */
        created_orders_count: number;
        /**
         * Номера созданных заказов
         * @example [1,2,3,4,5]
         */
        created_orders_numbers?: number[];
      },
      GenericBadRequestError | File | GenericError
    >({
      path: `/api/v1/transportation-orders/orders-file`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description Получить заказ по номеру
   *
   * @tags Transportation Orders
   * @name GetTransportationOrder
   * @summary Получение заказа
   * @request GET:/api/v1/transportation-orders/{transportation_order_number}
   */
  getTransportationOrder = (transportationOrderNumber: number, params: RequestParams = {}) =>
    this.request<TransportationOrder, GenericBadRequestError | GenericError>({
      path: `/api/v1/transportation-orders/${transportationOrderNumber}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Отредактировать заказы по её номеру
   *
   * @tags Transportation Orders
   * @name UpdateTransportationOrder
   * @summary Редактирование заказа
   * @request PUT:/api/v1/transportation-orders/{transportation_order_number}
   */
  updateTransportationOrder = (
    transportationOrderNumber: number,
    data: TransportationOrder,
    params: RequestParams = {},
  ) =>
    this.request<TransportationOrder, GenericBadRequestError | GenericError>({
      path: `/api/v1/transportation-orders/${transportationOrderNumber}`,
      method: 'PUT',
      body: data,
      ...params,
    });
  /**
   * @description Удалить заказ по её номеру
   *
   * @tags Transportation Orders
   * @name DeleteTransportationOrder
   * @summary Удаление заказа
   * @request DELETE:/api/v1/transportation-orders/{transportation_order_number}
   */
  deleteTransportationOrder = (transportationOrderNumber: number, params: RequestParams = {}) =>
    this.request<any, GenericBadRequestError | GenericError>({
      path: `/api/v1/transportation-orders/${transportationOrderNumber}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * @description Ручка обновляет статус заказа по её номеру на APPROVED
   *
   * @tags Transportation Orders
   * @name ApproveTransportationOrder
   * @summary Обновление статуса заказа на APPROVED
   * @request PATCH:/api/v1/transportation-orders/{transportation_order_number}/status/approved
   */
  approveTransportationOrder = (transportationOrderNumber: number, params: RequestParams = {}) =>
    this.request<TransportationOrder, GenericBadRequestError | GenericError>({
      path: `/api/v1/transportation-orders/${transportationOrderNumber}/status/approved`,
      method: 'PATCH',
      ...params,
    });
  /**
   * @description Ручка обновляет статус заказа по её номеру на CANCELED
   *
   * @tags Transportation Orders
   * @name CancelTransportationOrder
   * @summary Обновление статуса заказа на CANCELED
   * @request PATCH:/api/v1/transportation-orders/{transportation_order_number}/status/canceled
   */
  cancelTransportationOrder = (transportationOrderNumber: number, params: RequestParams = {}) =>
    this.request<TransportationOrder, GenericBadRequestError | GenericError>({
      path: `/api/v1/transportation-orders/${transportationOrderNumber}/status/canceled`,
      method: 'PATCH',
      ...params,
    });
  /**
   * @description Ручка обновляет статус заказа по её номеру на CARRIER_SELECTION
   *
   * @tags Transportation Orders
   * @name MakeTransportationOrderCarrierSelection
   * @summary Обновление статуса заказа на CARRIER_SELECTION
   * @request PATCH:/api/v1/transportation-orders/{transportation_order_number}/status/carrier-selection
   */
  makeTransportationOrderCarrierSelection = (transportationOrderNumber: number, params: RequestParams = {}) =>
    this.request<TransportationOrder, GenericBadRequestError | GenericError>({
      path: `/api/v1/transportation-orders/${transportationOrderNumber}/status/carrier-selection`,
      method: 'PATCH',
      ...params,
    });
  /**
   * @description Рассчитывает время и расстояние рейса с учетом объёма перевозимого груза
   *
   * @tags Transportation Orders
   * @name CreateTransportationOrderRoute
   * @summary Рассчитать маршрут
   * @request POST:/api/v1/transportation-orders/route
   */
  createTransportationOrderRoute = (data: TransportationOrder, params: RequestParams = {}) =>
    this.request<
      {
        /** Расписание перевозки */
        schedule?: Schedule;
      },
      GenericBadRequestError | GenericError
    >({
      path: `/api/v1/transportation-orders/route`,
      method: 'POST',
      body: data,
      ...params,
    });
}
