import { api } from '@/shared/api';
import type {
  Carrier,
  CarrierAggregate,
  GetCarriersParams,
} from '@/shared/api/generated-api/carriers/data-contracts.ts';
import {
  captureExceptionIfRequired,
  createCanceledErrorResponse,
  createEmptyResponseWithPagination,
  generalErrorHandler,
  isCanceledError,
} from '@/shared/lib/utils';
import type { CanceledErrorResponse, ResponseWithPagination } from '@/shared/types';

export const getCarrierById = async (carrierId: string): Promise<CarrierAggregate | null> => {
  try {
    const { data } = await api.carriers.getCarrierById(carrierId);

    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    return captureExceptionIfRequired(error, null);
  }
};

export const getCarriers = async (
  filter: GetCarriersParams,
  signal?: AbortSignal,
): Promise<ResponseWithPagination<Carrier> | CanceledErrorResponse> => {
  try {
    const { data } = await api['carriers'].getCarriers(filter, { signal });
    return data;
  } catch (error: any) {
    generalErrorHandler(error);

    return isCanceledError(error)
      ? createCanceledErrorResponse()
      : captureExceptionIfRequired(error, createEmptyResponseWithPagination<Carrier>());
  }
};
