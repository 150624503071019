import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import type { Api } from '@/shared/api/api.models';
import { apiErrorsInterceptor } from '@/shared/api/interceptors/api-error-interceptor.ts';

import { interceptorAuth } from './interceptor-auth';
import { interceptorAuthWms } from './interceptor-auth-wms';
import { interceptorWebAppVersion } from './interceptor-web-app-version';
import type { RequestInterceptor, ResponseInterceptor } from './types';

const requestInterceptors: RequestInterceptor[] = [interceptorAuth, interceptorAuthWms];
const responseInterceptors: ResponseInterceptor[] = [apiErrorsInterceptor, interceptorWebAppVersion];

export const addInterceptorsToApi = (api: Api): void => {
  requestInterceptors.forEach((interceptor) => {
    Object.entries(api).forEach(([apiName, apiValue]): void => {
      // @todo упростить логику
      if (
        (interceptor === interceptorAuthWms && ['auth', 'account'].includes(apiName)) ||
        (interceptor !== interceptorAuthWms && !['auth', 'account'].includes(apiName))
      ) {
        if (interceptor.onFulfilled) {
          apiValue.instance.interceptors.request.use(interceptor.onFulfilled);
        }
        if (interceptor.onRejected) {
          apiValue.instance.interceptors.request.use(
            (request: InternalAxiosRequestConfig<any>) => request,
            interceptor.onRejected,
          );
        }
      }
    });
  });

  responseInterceptors.forEach((interceptor) => {
    Object.entries(api).forEach(([apiName, apiValue]): void => {
      if (!['auth', 'account'].includes(apiName)) {
        if (interceptor.onFulfilled) {
          apiValue.instance.interceptors.response.use(interceptor.onFulfilled);
        }
        if (interceptor.onRejected) {
          apiValue.instance.interceptors.response.use(
            (response: AxiosResponse<any, any>) => response,
            (error: AxiosError<any, any>) => interceptor.onRejected && interceptor.onRejected(error, apiValue.instance),
          );
        }
      }
    });
  });
};
