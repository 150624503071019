import { api } from '@/shared/api';
import type {
  GetLogisticPointsParams,
  LogisticPoint,
} from '@/shared/api/generated-api/logistic-points/data-contracts.ts';
import {
  captureExceptionIfRequired,
  createCanceledErrorResponse,
  createEmptyResponseWithPagination,
  generalErrorHandler,
  isCanceledError,
} from '@/shared/lib/utils';
import type { CanceledErrorResponse, ResponseWithPagination } from '@/shared/types';

export const getLogisticPointById = async (logisticPointId: string): Promise<LogisticPoint | null> => {
  try {
    const { data } = await api.logisticPoints.getLogisticPointById(logisticPointId);

    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    return captureExceptionIfRequired(error, null);
  }
};

export const getLogisticPoints = async (
  filter: GetLogisticPointsParams,
  signal?: AbortSignal,
): Promise<ResponseWithPagination<LogisticPoint> | CanceledErrorResponse> => {
  try {
    const { data } = await api.logisticPoints.getLogisticPoints(filter, { signal });
    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    return isCanceledError(error)
      ? createCanceledErrorResponse()
      : captureExceptionIfRequired(error, createEmptyResponseWithPagination<LogisticPoint>());
  }
};

export const getLogisticPointByShortName = async (logisticPointShortName: string): Promise<LogisticPoint | null> => {
  try {
    const { data } = await api.logisticPoints.getLogisticPointByShortName(logisticPointShortName);

    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    return captureExceptionIfRequired(error, null);
  }
};

export const createLogisticPoint = async (logisticPoint: LogisticPoint): Promise<LogisticPoint | null> => {
  try {
    const { data } = await api.logisticPoints.createLogisticPoint(logisticPoint);

    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    return captureExceptionIfRequired(error, null);
  }
};

export const updateLogisticPoint = async (id: string, logisticPoint: LogisticPoint): Promise<LogisticPoint | null> => {
  try {
    const { data } = await api.logisticPoints.updateLogisticPoint(id, logisticPoint);

    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    return captureExceptionIfRequired(error, null);
  }
};
