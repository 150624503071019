import { defineStore } from 'pinia';

export interface State {
  version: string;
}

export const useWebVersionStore = defineStore('web-version', {
  state: (): State => ({
    version: '',
  }),
  actions: {
    setVersion(version: string): void {
      this.version = version;
    },
  },
});
