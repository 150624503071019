import type { MIconNames } from '@mm-frontend/mithril-ui-kit';
import { helpers, minLength, required, requiredIf } from '@vuelidate/validators';
import type { Ref } from 'vue';

import { BodyType, VehicleType } from '@/shared/api/generated-api/fleets/data-contracts';
import type { ModelVehicleTypeEnum } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import {
  createMaxValueMessage,
  createMinLengthMessage,
  createMinValueMessageStrict,
  createOneFieldLessThanSecondMessage,
  REQUIRED_MESSAGE,
} from '@/shared/config/validation';
import { readableEnum } from '@/shared/lib/utils';

import type { FormModel } from './types';

export const defaultModel: FormModel = {
  brand: '',
  model: '',
  bodyType: undefined,
  vehicleMass: undefined,
  permissibleMaximumMass: undefined,
  vehicleType: undefined,
  loadCapacity: 0,
  bodyWidth: undefined,
  bodyHeight: undefined,
  bodyDepth: undefined,
  passengersCapacity: 0,
  cargoSpace: 0,
};

export const readableVehicleType = readableEnum<VehicleType | ModelVehicleTypeEnum>({
  [VehicleType.VEHICLE_TYPE_CARGO]: 'Грузовой фургон',
  [VehicleType.VEHICLE_TYPE_TRAILER]: 'Прицеп',
  [VehicleType.VEHICLE_TYPE_CAR]: 'Легковой',
  [VehicleType.VEHICLE_TYPE_PASSENGER]: 'Пассажирский',
  [VehicleType.VEHICLE_TYPE_SPECIAL]: 'Спецтехника',
  [VehicleType.VEHICLE_TYPE_TRACTOR]: 'Тягач',
});

export const readableBodyType = readableEnum<BodyType>({
  [BodyType.BODY_TYPE_TENT]: 'Тент',
  [BodyType.BODY_TYPE_ISOMETRIC]: 'Изотермический',
  [BodyType.BODY_TYPE_OPEN]: 'Открытый',
  [BodyType.BODY_TYPE_METAL]: 'Цельнометаллический',
  [BodyType.BODY_TYPE_REFRIGERATOR]: 'Рефрижератор',
});

export const iconFromCarType = readableEnum<VehicleType | ModelVehicleTypeEnum, MIconNames>({
  [VehicleType.VEHICLE_TYPE_CARGO]: 'vehicle-truck-profile',
  [VehicleType.VEHICLE_TYPE_TRAILER]: 'transport_trailer',
  [VehicleType.VEHICLE_TYPE_CAR]: 'transport_car',
  [VehicleType.VEHICLE_TYPE_PASSENGER]: 'transport_bus',
  [VehicleType.VEHICLE_TYPE_SPECIAL]: 'transport_special',
  [VehicleType.VEHICLE_TYPE_TRACTOR]: 'transport_truck',
});

export const createModelValidationRules = (
  model: Ref<{
    vehicleType?: VehicleType;
    permissibleMaximumMass?: number;
  } | null>,
): {
  brand: object;
  model: object;
  vehicleType: object;
  bodyType?: object;
  vehicleMass?: object;
  permissibleMaximumMass?: object;
  bodyDepth?: object;
  bodyWidth?: object;
  bodyHeight?: object;
  passengersCapacity: object;
} => {
  const requiredValidationByType = (vehicleTypes: VehicleType[]) =>
    helpers.withMessage(
      REQUIRED_MESSAGE,
      requiredIf(() => !!model.value?.vehicleType && vehicleTypes.includes(model.value.vehicleType)),
    );
  const numberFieldValidationByType = (vehicleTypes: VehicleType[], maxValue: number) => {
    const isMinMaxValidatorEnabled = (value?: string | number): value is string | number =>
      Boolean(
        value !== undefined &&
          String(value).length > 0 &&
          model.value?.vehicleType &&
          vehicleTypes.includes(model.value.vehicleType),
      );
    return {
      requiredIf: requiredValidationByType(vehicleTypes),
      minValue: helpers.withMessage(createMinValueMessageStrict(0), (value?: string | number) =>
        isMinMaxValidatorEnabled(value) ? +value > 0 : true,
      ),
      maxValue: helpers.withMessage(createMaxValueMessage(maxValue), (value?: string | number) =>
        isMinMaxValidatorEnabled(value) ? +value <= maxValue : true,
      ),
    };
  };

  const trailerAndCargoVehicleTypes = [VehicleType.VEHICLE_TYPE_TRAILER, VehicleType.VEHICLE_TYPE_CARGO];

  const trailerCarAndCargoVehicleTypes = [...trailerAndCargoVehicleTypes, VehicleType.VEHICLE_TYPE_CAR];
  const trailerCarCargoAndTractorVehicleTypes = [...trailerCarAndCargoVehicleTypes, VehicleType.VEHICLE_TYPE_TRACTOR];

  return {
    brand: {
      required: helpers.withMessage(REQUIRED_MESSAGE, required),
      minLength: helpers.withMessage(createMinLengthMessage(2), minLength(2)),
    },
    model: {
      required: helpers.withMessage(REQUIRED_MESSAGE, required),
      minLength: helpers.withMessage(createMinLengthMessage(2), minLength(2)),
    },
    vehicleType: {
      required: helpers.withMessage(REQUIRED_MESSAGE, required),
    },
    bodyType: {
      requiredIf: requiredValidationByType(trailerAndCargoVehicleTypes),
    },
    vehicleMass: {
      ...numberFieldValidationByType(trailerCarCargoAndTractorVehicleTypes, 100),
      lessThanPermissibleMaximumMass: helpers.withMessage(
        createOneFieldLessThanSecondMessage('Разрешенная max масса'),
        (value?: number) => {
          return !!(
            (!value && !model.value?.permissibleMaximumMass) ||
            (!value && model.value?.permissibleMaximumMass) ||
            (value && !model.value?.permissibleMaximumMass) ||
            (value && model.value?.permissibleMaximumMass && value < model.value.permissibleMaximumMass)
          );
        },
      ),
    },
    permissibleMaximumMass: numberFieldValidationByType(trailerCarCargoAndTractorVehicleTypes, 1000),
    bodyDepth: numberFieldValidationByType(trailerCarAndCargoVehicleTypes, 100),
    bodyHeight: numberFieldValidationByType(trailerCarAndCargoVehicleTypes, 10),
    bodyWidth: numberFieldValidationByType(trailerCarAndCargoVehicleTypes, 10),
    passengersCapacity: numberFieldValidationByType(
      [VehicleType.VEHICLE_TYPE_PASSENGER, VehicleType.VEHICLE_TYPE_CAR],
      1000,
    ),
  };
};
