import { canceledErrorName } from '../../config/error.ts';
import type { CanceledErrorResponse, Page, ResponseWithList, ResponseWithPagination } from '../../types';

const createEmptyPage = (): Page => ({
  size: 0,
  number: 0,
});

export const createEmptyResponseWithList = <T>(): ResponseWithList<T> => ({ list: [] });

export const createEmptyResponseWithPagination = <T>(): ResponseWithPagination<T> => ({
  ...createEmptyResponseWithList<T>(),
  pagination: {
    self: createEmptyPage(),
    first: createEmptyPage(),
    last: createEmptyPage(),
    size: 0,
  },
});

export const isCanceledErrorResponse = (response?: CanceledErrorResponse | any): response is CanceledErrorResponse =>
  response?.responseType === canceledErrorName;

export const createCanceledErrorResponse = (): CanceledErrorResponse => ({
  responseType: canceledErrorName,
});
