import { readableEnum, type MDropItem, type MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { PrintFormType } from '@/entities/print-form';
import type { DeploymentDto } from '@/shared/api/generated-api/document-generator/data-contracts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';

export const printFormPerPageSize = 50;

export const route: RouteRecordRaw = {
  path: 'print-forms',
  name: RouteName.PRINT_FORMS,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/PrintForms.vue');
  },
};

const printFormKeyToTextMap = readableEnum<string, string, true>({
  [PrintFormType.TRN]: 'Транспортная накладная (ТрН)',
  [PrintFormType.APP]: 'Акт приема-передачи (АПП)',
});

export const tableColumns: MTableColumn<DeploymentDto>[] = [
  {
    label: 'Тип документа',
    value: 'key',
    customRender: (row) => printFormKeyToTextMap(row.key),
  },
  {
    label: 'Версия',
    value: 'version',
  },
  {
    label: 'Дата и время загрузки',
    value: 'createdAt',
  },
  { label: '', value: 'action' },
];

export const dropList: MDropItem<PrintFormType>[] = [
  {
    text: 'Добавить версию ТрН',
    action: PrintFormType.TRN,
  },
  {
    text: 'Добавить версию АПП',
    action: PrintFormType.APP,
  },
];
