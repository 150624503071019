import { readableEnum, type MOptionItem, type MRadioItem, type MStatusDotColor } from '@mm-frontend/mithril-ui-kit';
import { between, helpers, required, requiredIf } from '@vuelidate/validators';
import type { Ref } from 'vue';

import type { FormModel, FormModelSchedule } from '@/entities/logistic-points/types.ts';
import {
  DayOfWeek,
  LegalEntityType,
  LogisticPointStatus,
  LogisticPointType,
  OwnershipType,
} from '@/shared/api/generated-api/logistic-points/data-contracts.ts';
import { timezones } from '@/shared/config/time.ts';

export const readableLogisticPointStatus = readableEnum<LogisticPointStatus>({
  [LogisticPointStatus.LOGISTIC_POINT_STATUS_OPEN]: 'Открыт',
  [LogisticPointStatus.LOGISTIC_POINT_STATUS_CLOSED]: 'Закрыт',
});

export const readableLogisticPointType = readableEnum<LogisticPointType>({
  [LogisticPointType.LOGISTIC_POINT_TYPE_PICKUP_POINT]: 'ПВЗ',
  [LogisticPointType.LOGISTIC_POINT_TYPE_SORTING_CENTER]: 'СЦ',
  [LogisticPointType.LOGISTIC_POINT_TYPE_CROSSDOCK]: 'Кросс-док',
  [LogisticPointType.LOGISTIC_POINT_TYPE_FULLFILLMENT]: 'ФФ',
  [LogisticPointType.LOGISTIC_POINT_TYPE_DROPOFF_POINT]: 'Дроп оф',
  [LogisticPointType.LOGISTIC_POINT_TYPE_DISTRIBUTION_CENTER]: 'Распределительный центр',
  [LogisticPointType.LOGISTIC_POINT_TYPE_SELLER]: 'Селлер',
});

export const readableLogisticPointOwnershipType = readableEnum<OwnershipType>({
  [OwnershipType.OWNERSHIP_TYPE_MM]: 'Собственный',
  [OwnershipType.OWNERSHIP_TYPE_PARTNER]: 'Партнерский',
  [OwnershipType.OWNER_SHIP_TYPE_BUSINESS_PARTNER]: 'Партнерский',
});

export const readableLogisticPointLegalEntityType = readableEnum<LegalEntityType>({
  [LegalEntityType.LEGAL_TYPE_INDIVIDUAL]: 'ИП',
  [LegalEntityType.LEGAL_TYPE_LEGAL]: 'Юр. лицо',
});

export const readableDayOfWeekShort = readableEnum<DayOfWeek>({
  [DayOfWeek.DAY_OF_WEEK_MONDAY]: 'Пн',
  [DayOfWeek.DAY_OF_WEEK_TUESDAY]: 'Вт',
  [DayOfWeek.DAY_OF_WEEK_WEDNESDAY]: 'Ср',
  [DayOfWeek.DAY_OF_WEEK_THURSDAY]: 'Чт',
  [DayOfWeek.DAY_OF_WEEK_FRIDAY]: 'Пт',
  [DayOfWeek.DAY_OF_WEEK_SATURDAY]: 'Сб',
  [DayOfWeek.DAY_OF_WEEK_SUNDAY]: 'Вс',
});

export const logisticPointTypeOptions: MOptionItem[] = Object.values(LogisticPointType).map(
  (logisticPointType: LogisticPointType) => ({
    title: readableLogisticPointType(logisticPointType),
    id: logisticPointType,
  }),
);

export const logisticPointStatusSelectOptions: MOptionItem[] = Object.values(LogisticPointStatus)
  .reverse()
  .map((logisticPointStatus: LogisticPointStatus) => ({
    title: readableLogisticPointStatus(logisticPointStatus),
    id: logisticPointStatus,
  }));

export const logisticPointStatusRadioOptions: MRadioItem<LogisticPointStatus>[] = Object.values(LogisticPointStatus)
  .reverse()
  .map((logisticPointStatus: LogisticPointStatus) => ({
    label: readableLogisticPointStatus(logisticPointStatus),
    value: logisticPointStatus,
  }));

export const logisticPointStatusDotColorMap: Record<LogisticPointStatus, MStatusDotColor> = {
  [LogisticPointStatus.LOGISTIC_POINT_STATUS_OPEN]: 'green',
  [LogisticPointStatus.LOGISTIC_POINT_STATUS_CLOSED]: 'red',
};

export const logisticPointScheduleDayOfWeekMap: Record<number, DayOfWeek> = {
  0: DayOfWeek.DAY_OF_WEEK_SUNDAY,
  1: DayOfWeek.DAY_OF_WEEK_MONDAY,
  2: DayOfWeek.DAY_OF_WEEK_TUESDAY,
  3: DayOfWeek.DAY_OF_WEEK_WEDNESDAY,
  4: DayOfWeek.DAY_OF_WEEK_THURSDAY,
  5: DayOfWeek.DAY_OF_WEEK_FRIDAY,
  6: DayOfWeek.DAY_OF_WEEK_SUNDAY,
};

export const MIN_SEARCH_LENGTH = 2;

export const addLogisticPointTypeOptions: MOptionItem[] = [
  LogisticPointType.LOGISTIC_POINT_TYPE_CROSSDOCK,
  LogisticPointType.LOGISTIC_POINT_TYPE_DISTRIBUTION_CENTER,
  LogisticPointType.LOGISTIC_POINT_TYPE_DROPOFF_POINT,
  LogisticPointType.LOGISTIC_POINT_TYPE_FULLFILLMENT,
  LogisticPointType.LOGISTIC_POINT_TYPE_SELLER,
  LogisticPointType.LOGISTIC_POINT_TYPE_SORTING_CENTER,
].map((logisticPointType: LogisticPointType) => ({
  title: readableLogisticPointType(logisticPointType),
  id: logisticPointType,
}));

export const ownershipTypeOptions: MRadioItem<OwnershipType>[] = [
  OwnershipType.OWNERSHIP_TYPE_MM,
  OwnershipType.OWNERSHIP_TYPE_PARTNER,
].map((ownershipType: OwnershipType) => ({
  label: readableLogisticPointOwnershipType(ownershipType),
  value: ownershipType,
}));

export const legalEntityTypeOptions: MRadioItem<LegalEntityType>[] = Object.values(LegalEntityType).map(
  (legalEntityType: LegalEntityType) => ({
    label: readableLogisticPointLegalEntityType(legalEntityType),
    value: legalEntityType,
  }),
);

export const dayOfWeekOptions = [
  DayOfWeek.DAY_OF_WEEK_MONDAY,
  DayOfWeek.DAY_OF_WEEK_TUESDAY,
  DayOfWeek.DAY_OF_WEEK_WEDNESDAY,
  DayOfWeek.DAY_OF_WEEK_THURSDAY,
  DayOfWeek.DAY_OF_WEEK_FRIDAY,
  DayOfWeek.DAY_OF_WEEK_SATURDAY,
  DayOfWeek.DAY_OF_WEEK_SUNDAY,
].map((dayOfWeek: DayOfWeek) => ({
  label: readableDayOfWeekShort(dayOfWeek),
  value: dayOfWeek,
}));

export const createEmptyFormModelSchedule = (checked = true): FormModelSchedule[] =>
  dayOfWeekOptions.map((day) => ({
    checked,
    dayOfWeek: day.value,
    openedAt: '',
    closedAt: '',
    breakStartAt: undefined,
    breakEndAt: undefined,
  }));

export const defaultModel: FormModel = {
  shortName: '',
  type: undefined,
  status: LogisticPointStatus.LOGISTIC_POINT_STATUS_OPEN,
  region: '',
  regionCode: '',
  city: '',
  street: '',
  house: '',
  postalCode: '',
  latitude: '',
  longitude: '',
  timezone: '',
  legalType: LegalEntityType.LEGAL_TYPE_INDIVIDUAL,
  ownershipType: OwnershipType.OWNERSHIP_TYPE_MM,
  legalEntityName: '',
  legalEntityAddress: '',
  inn: '',
  kpp: '',
  ogrn: '',
  schedule: createEmptyFormModelSchedule(),
  allDayWork: false,
  legalEntityPhone: '',
};

export const timezonesTypeOptions: MOptionItem[] = Object.keys(timezones).map((timezone: string) => ({
  title: timezone,
  id: timezone,
}));

export const DEFAULT_MAP_CENTER: [number, number] = [55.753_575, 37.620_941];

export const DEFAULT_MAP_ZOOM = 10;

const INCORRECT_VALUE_MESSAGE = 'Введите корректное значение';

const scheduleRequiredValidator = (value: string, currenObject: FormModelSchedule): boolean =>
  !currenObject.checked || Boolean(value);
const scheduleOpenBeforeCloseValidator = (value: string, currenObject: FormModelSchedule): boolean =>
  !value || !currenObject.closedAt || value <= currenObject.closedAt;
const scheduleBreakStartBeforeBreakEndValidator = (value: string, currenObject: FormModelSchedule): boolean =>
  !value || !currenObject.breakEndAt || value <= currenObject.breakEndAt;

export const createLogisticPointDataValidationRules = (
  logisticPoint: Ref<
    | {
        legalType: LegalEntityType;
      }
    | null
    | undefined
  >,
): {
  shortName: object;
  type?: object;
  region: object;
  regionCode: object;
  city: object;
  street: object;
  house: object;
  postalCode: object;
  latitude: object;
  longitude: object;
  timezone: object;
  legalEntityName: object;
  ogrn: object;
  inn: object;
  kpp?: object;
  legalEntityAddress: object;
  legalEntityPhone: object;
  schedule: object;
} => {
  return {
    shortName: {
      required: helpers.withMessage('', required),
    },
    type: {
      required: helpers.withMessage('', required),
    },
    region: {
      required: helpers.withMessage('', required),
    },
    regionCode: {
      required: helpers.withMessage('', required),
      inCorrectRange: helpers.withMessage(INCORRECT_VALUE_MESSAGE, between(0, 200)),
    },
    city: {
      required: helpers.withMessage('', required),
    },
    street: {
      required: helpers.withMessage('', required),
    },
    house: {
      required: helpers.withMessage('', required),
    },
    postalCode: {
      required: helpers.withMessage('', required),
      length: helpers.withMessage(INCORRECT_VALUE_MESSAGE, (value: string) => !value || value.length === 6),
    },
    latitude: {
      required: helpers.withMessage('', required),
      inCorrectRange: helpers.withMessage(INCORRECT_VALUE_MESSAGE, between(-90, 90)),
    },
    longitude: {
      required: helpers.withMessage('', required),
      inCorrectRange: helpers.withMessage(INCORRECT_VALUE_MESSAGE, between(-180, 180)),
    },
    timezone: {
      required: helpers.withMessage('', required),
    },
    legalEntityName: {
      required: helpers.withMessage('', required),
    },
    ogrn: {
      required: helpers.withMessage('', required),
      length: helpers.withMessage(
        INCORRECT_VALUE_MESSAGE,
        (value: string) =>
          !value ||
          !logisticPoint.value ||
          value.length === (logisticPoint.value.legalType === LegalEntityType.LEGAL_TYPE_INDIVIDUAL ? 15 : 13),
      ),
    },
    inn: {
      required: helpers.withMessage('', required),
      length: helpers.withMessage(
        INCORRECT_VALUE_MESSAGE,
        (value: string) =>
          !value ||
          !logisticPoint.value ||
          value.length === (logisticPoint.value.legalType === LegalEntityType.LEGAL_TYPE_INDIVIDUAL ? 12 : 10),
      ),
    },
    kpp: {
      required: helpers.withMessage(
        '',
        requiredIf(() =>
          Boolean(logisticPoint.value && logisticPoint.value.legalType === LegalEntityType.LEGAL_TYPE_LEGAL),
        ),
      ),
      length: helpers.withMessage(
        INCORRECT_VALUE_MESSAGE,
        (value: string) =>
          !value ||
          !logisticPoint.value ||
          logisticPoint.value.legalType !== LegalEntityType.LEGAL_TYPE_LEGAL ||
          value.length === 9,
      ),
    },
    legalEntityAddress: {
      required: helpers.withMessage('', required),
    },
    legalEntityPhone: {
      required: helpers.withMessage('', required),
    },
    schedule: {
      $each: helpers.forEach({
        openedAt: {
          required: helpers.withMessage('', scheduleRequiredValidator),
          scheduleOpenBeforeClose: helpers.withMessage('Открытие позже закрытия', scheduleOpenBeforeCloseValidator),
        },
        closedAt: {
          required: helpers.withMessage('', scheduleRequiredValidator),
        },
        breakStartAt: {
          requireIfCloseFilled: helpers.withMessage(
            '',
            (value: string, currenObject: FormModelSchedule): boolean =>
              (!value && !currenObject.breakEndAt) || Boolean(currenObject.breakEndAt && value) || Boolean(value),
          ),
          startBeforeClose: helpers.withMessage(
            'Начало перерыва позже окончания',
            scheduleBreakStartBeforeBreakEndValidator,
          ),
        },
        breakEndAt: {
          requireIfStartFilled: helpers.withMessage(
            '',
            (value: string, currenObject: FormModelSchedule): boolean =>
              (!value && !currenObject.breakStartAt) || Boolean(currenObject.breakStartAt && value) || Boolean(value),
          ),
        },
      }),
    },
  };
};
