export const timezones: Record<string, string> = {
  'Europe/Kaliningrad': 'KALT',
  'Europe/Moscow': 'MSK',
  'Europe/Kirov': 'MSK',
  'Europe/Simferopol': 'MSK',
  'Europe/Volgograd': 'MSK',
  'Europe/Astrakhan': 'SAMT',
  'Europe/Samara': 'SAMT',
  'Europe/Saratov': 'SAMT',
  'Europe/Ulyanovsk': 'SAMT',
  'Asia/Yekaterinburg': 'YEKT',
  'Asia/Omsk': 'OMST',
  'Asia/Barnaul': 'KRAT',
  'Asia/Krasnoyarsk': 'KRAT',
  'Asia/Novokuznetsk': 'KRAT',
  'Asia/Novosibirsk': 'KRAT',
  'Asia/Tomsk': 'KRAT',
  'Asia/Irkutsk': 'IRKT',
  'Asia/Chita': 'YAKT',
  'Asia/Khandyga': 'YAKT',
  'Asia/Yakutsk': 'YAKT',
  'Asia/Ust-Nera': 'VLAT',
  'Asia/Vladivostok': 'VLAT',
  'Asia/Magadan': 'MAGT',
  'Asia/Sakhalin': 'MAGT',
  'Asia/Srednekolymsk': 'MAGT',
  'Asia/Anadyr': 'PETT',
  'Asia/Kamchatka': 'PETT',
};
