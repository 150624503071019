import { defineStore } from 'pinia';
import type { LocationQuery, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteParams } from 'vue-router';

import { RouteName } from '@/shared/config/router';

export interface NextRoute {
  name: RouteName;
  params: RouteParams;
  query: LocationQuery;
}

export interface CacheState {
  nextRoute: NextRoute | null;
}

export const useCacheStore = defineStore('cache', {
  state: (): CacheState => ({
    nextRoute: null,
  }),
  actions: {
    setNextRoute(route: RouteLocationNormalized | RouteLocationNormalizedLoaded): void {
      this.nextRoute = {
        name: typeof route.name === 'string' ? (route.name as RouteName) : RouteName.DRIVERS,
        params: route.params,
        query: route.query,
      };
    },
  },
  persist: true,
});
