<template>
  <Suspense>
    <component :is="layoutComponent"></component>
  </Suspense>
</template>

<script setup lang="ts">
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { UAParser } from 'ua-parser-js';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { layoutComponents } from '@/app/router';
import { LayoutType } from '@/shared/config/router';
import { useAuthStore, useDeviceInfoStore } from '@/shared/config/stores';

const route = useRoute();

const deviceInfoStore = useDeviceInfoStore();

const layoutComponent = computed(() => {
  const layoutName = (route.meta.layout ??
    (useAuthStore().accessToken ? LayoutType.DEFAULT : LayoutType.LOGIN)) as LayoutType;
  return layoutComponents[layoutName];
});

const initDeviceInfo = async (): Promise<void> => {
  getFingerprint().then(deviceInfoStore.setFingerprint);

  const ua = new UAParser();
  deviceInfoStore.setOS(await ua.getOS().withClientHints());
  deviceInfoStore.setBrowser(ua.getBrowser());
  deviceInfoStore.setDevice(ua.getDevice());
  deviceInfoStore.setEngine(ua.getEngine());
};

initDeviceInfo();
</script>

<style scoped lang="scss"></style>
