import type { HardcodeError } from '../types';

export const hardcodeErrors = new Map<number, HardcodeError>([
  [
    426,
    {
      title: 'Обновите приложение',
      description:
        'Текущая версия веб-приложения устарела. Она может работать с ошибками и не поддерживает новые функции',
    },
  ],
  [
    500,
    {
      title: 'Внутренняя ошибка сервера',
      blockquote:
        'Возникла техническая проблема на сервере. Попробуйте снова через несколько минут. Если проблема сохраняется, обратитесь в техническую поддержку.',
    },
  ],
  [
    501,
    {
      title: 'Функция временно недоступна',
      blockquote: 'Запрошенная функция пока не поддерживается. Пожалуйста, проверьте обновления позже.',
    },
  ],
  [
    502,
    {
      title: 'Проблемы на сервере',
      blockquote:
        'Сбой в работе сервера. Попробуйте снова через несколько минут. Если это не поможет, обратитесь в техническую поддержку.',
    },
  ],
  [
    503,
    {
      title: 'Сервис временно недоступен',
      blockquote:
        'Наши серверы перегружены или проходят обслуживание. Если вам срочно нужна помощь, свяжитесь с нашей технической поддержкой.',
    },
  ],
  [
    504,
    {
      title: 'Слишком долгое ожидание',
      blockquote:
        'Запрос не был выполнен вовремя. Проверьте подключение к интернету и попробуйте позже. Если проблема повторяется, обратитесь в техническую поддержку.',
    },
  ],
  [
    505,
    {
      title: 'Обновите приложение',
      blockquote: 'Ваша версия приложения устарела. Сбросьте кеш, затем обновите страницу или обратитесь в поддержку.',
    },
  ],
]);

export const canceledErrorName = 'CanceledError';
export const refreshErrorName = 'RefreshError';
