/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { File } from 'buffer';

import { Error, HttpMethod, WaybillRoute } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const DocumentsRoutesRegExp = {
  getTransferAct: {
    regExp: '^\\/api\\/v1\\/shipments\\/\\d+\\/transfer-act$',
    method: HttpMethod.GET,
  },
  getWaybill: {
    regExp: '^\\/api\\/v1\\/shipments\\/\\d+\\/waybill$',
    method: HttpMethod.GET,
  },
  getTripPossibleRoutes: {
    regExp: '^\\/api\\/v1\\/trips\\/\\d+\\/possible-routes$',
    method: HttpMethod.GET,
  },
  getWaybills: {
    regExp: '^\\/api\\/v1\\/trips\\/\\d+\\/waybills$',
    method: HttpMethod.POST,
  },
};

export class Documents<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Получить акт приема-передачи по ID отгрузки
   *
   * @tags Documents
   * @name GetTransferAct
   * @summary Получение акта приема-передачи
   * @request GET:/api/v1/shipments/{shipment_id}/transfer-act
   * @secure
   */
  getTransferAct = (shipmentId: number, params: RequestParams = {}) =>
    this.request<File, Error>({
      path: `/api/v1/shipments/${shipmentId}/transfer-act`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description Получить транспортную накладную по ID отгрузки
   *
   * @tags Documents
   * @name GetWaybill
   * @summary Получение транспортной накладной
   * @request GET:/api/v1/shipments/{shipment_id}/waybill
   * @secure
   */
  getWaybill = (shipmentId: number, params: RequestParams = {}) =>
    this.request<File, Error>({
      path: `/api/v1/shipments/${shipmentId}/waybill`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description Получить список всех возможных комбинаций между логистическими точками маршрута
   *
   * @tags Documents
   * @name GetTripPossibleRoutes
   * @summary Получение списка возможных маршрутов
   * @request GET:/api/v1/trips/{trip_number}/possible-routes
   * @secure
   */
  getTripPossibleRoutes = (tripNumber: number, params: RequestParams = {}) =>
    this.request<WaybillRoute[], Error>({
      path: `/api/v1/trips/${tripNumber}/possible-routes`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description Получить транспортные накладные по выбранным точкам маршрута
   *
   * @tags Documents
   * @name GetWaybills
   * @summary Получение транспортных накладных по точкам маршрута
   * @request POST:/api/v1/trips/{trip_number}/waybills
   * @secure
   */
  getWaybills = (tripNumber: number, data: WaybillRoute[], params: RequestParams = {}) =>
    this.request<Blob, Error>({
      path: `/api/v1/trips/${tripNumber}/waybills`,
      method: 'POST',
      body: data,
      secure: true,
      format: 'blob',
      ...params,
    });
}
