import { notificationManager, type OpenModalOptions } from '@mm-frontend/mithril-ui-kit';

import type { ValidationError } from '@/shared/api/generated-api/transportation-orders/data-contracts.ts';
import { hardcodeErrors } from '@/shared/config/error';
import { isCanceledError, isRefreshError } from '@/shared/lib/utils/check-error.ts';

import { refreshBrowser } from './refresh';

/**
 * Преобразует объект ошибки в объект OpenModalOptions для уведомлений.
 *
 * @param error - Преобразуемый объект ошибки
 * @return Преобразованный объект OpenModalOptions для уведомлений
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertErrorToNotification = (error: any): OpenModalOptions => {
  const code = error.response?.data?.code ?? '';
  const requestId = error.response?.headers?.['x-request-id'];
  const traceId = error.response?.headers?.['x-trace-id'];
  const debugMessage = error.response?.data?.debugMessage ?? error.response?.data?.message;
  const status = error.response?.status;
  const validationErrors: string[] = [];
  const hardcodeErrorInfo = hardcodeErrors.get(status);

  const title = hardcodeErrorInfo?.title ?? error.response?.data?.title ?? 'Неизвестная ошибка';
  const description = hardcodeErrorInfo ? hardcodeErrorInfo.description : (error.response?.data?.detail ?? '');
  const hardcodeErrorBlockquote = hardcodeErrorInfo?.blockquote;

  if (status === 426)
    return {
      title,
      description,
      actions: [
        {
          text: 'Обновить',
          handler: refreshBrowser,
        },
      ],
    };

  if (error.response?.data?.validationErrors) {
    error.response.data.validationErrors.forEach((validationError: ValidationError) =>
      validationErrors.push(validationError.message),
    );
  }

  const blockquote: string[] = [];
  if (hardcodeErrorBlockquote) {
    blockquote.push(hardcodeErrorBlockquote, '<br/>');
  }
  if (code) {
    blockquote.push('Индивидуальный код ошибки:', `<b>${code}</b>`, '<br/>');
  }
  if (requestId) {
    blockquote.push('Уникальный идентификатор запроса:', `<b>${requestId}</b>`, '<br/>');
  }
  if (traceId) {
    blockquote.push('Trace Id:', `<b>${traceId}</b>`, '<br/>');
  }
  if (status) {
    blockquote.push('Статус код:', `<b>${status}</b>`, '<br/>');
  }
  if (validationErrors.length) {
    blockquote.push(...validationErrors, '<br/>');
  } else if (debugMessage) {
    blockquote.push(debugMessage, '<br/>');
  }

  blockquote.push(
    'Контакты технической поддержки:',
    // eslint-disable-next-line no-secrets/no-secrets
    '\u2022 Телеграмм канал: <a href="https://t.me/+7FsiJ-vz5lIyMDRi">@TMS / Technical Support</a>',
  );

  return {
    title,
    description,
    blockquote,
    actions: [
      {
        text: 'Закрыть',
        handler: ({ close }): void => {
          close();
        },
        buttonProps: {
          variant: 'filled-gray',
        },
      },
      {
        text: 'Скопировать',
        handler: async (): Promise<void> => {
          const copyData = [`Заголовок: ${title}.`];
          if (description) {
            copyData.push(`Подробное описание проблемы: ${description}.`);
          }
          if (code) {
            copyData.push(`Индивидуальный код ошибки: ${code}.`);
          }
          if (requestId) {
            copyData.push(`Уникальный идентификатор запроса: ${requestId}.`);
          }
          if (traceId) {
            copyData.push(`Trace Id: ${traceId}.`);
          }
          if (status) {
            copyData.push(`Статус код: ${status}.`);
          }
          if (debugMessage) {
            copyData.push(`Текст ошибки для команды разработки: ${debugMessage}`);
          }
          if (validationErrors.length) {
            copyData.push(`Дополнительная информация: ${validationErrors.join('. ')}`);
          }

          await navigator.clipboard.writeText(copyData.join('\r\n'));
          notificationManager.openToast('ok', 'Данные скопированы в буфер обмена');
        },
      },
    ],
  };
};

/**
 * Обрабатывает общие ошибки, проверяя, являются ли они типом TMS, и если да, открывает уведомление toast с соответствующим сообщением об ошибке.
 * (in future) Если ошибка является экземпляром ZodError, открывается модальное уведомление с предопределенным заголовком, описанием и массивом сообщений об ошибках из объекта ZodError.
 *
 * @param {any} error - Ошибка, которую нужно обработать.
 * @param {boolean} ignoreNotification -Нужно ли игнорировать ошибку, не показывая нотификацию
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const generalErrorHandler = (error: any, ignoreNotification = false): void => {
  if (ignoreNotification || isCanceledError(error) || isRefreshError(error)) {
    return;
  }

  notificationManager.openModal('warning', convertErrorToNotification(error));
};
