/**
 * Переводит рубли в копейки
 *
 * @param {number=} value - Сумма в рублях
 * @return {number} Сумма в копейках.
 */
export const formatRubleToKopeck = (value = 0): number => Math.round(value * 100);

/**
 * Переводит копейки в рубли
 *
 * @param {number=} value - Сумма в копейках
 * @return {number} Сумма в рублях.
 */
export const formatKopeckToRuble = (value = 0): number => value / 100;
