import { VehicleType } from '@/shared/api/generated-api/fleets/data-contracts.ts';
import { ModelVehicleTypeEnum } from '@/shared/api/generated-api/transportation-orders/data-contracts.ts';

export const modelVehicleTypeToTransportationOrderModelVehicleTypeMap: Record<VehicleType, ModelVehicleTypeEnum> = {
  [VehicleType.VEHICLE_TYPE_CARGO]: ModelVehicleTypeEnum.VEHICLE_TYPE_CARGO,
  [VehicleType.VEHICLE_TYPE_TRAILER]: ModelVehicleTypeEnum.VEHICLE_TYPE_TRAILER,
  [VehicleType.VEHICLE_TYPE_CAR]: ModelVehicleTypeEnum.VEHICLE_TYPE_CAR,
  [VehicleType.VEHICLE_TYPE_PASSENGER]: ModelVehicleTypeEnum.VEHICLE_TYPE_PASSENGER,
  [VehicleType.VEHICLE_TYPE_SPECIAL]: ModelVehicleTypeEnum.VEHICLE_TYPE_SPECIAL,
  [VehicleType.VEHICLE_TYPE_TRACTOR]: ModelVehicleTypeEnum.VEHICLE_TYPE_TRACTOR,
};
