import type { OptionItem } from '@/entities/carrier';
import type { LogisticPoint } from '@/shared/api/generated-api/logistic-points/data-contracts';
import type {
  ProblemType,
  TransportationType,
  TripDriverStatus,
  TripEventType,
  TripStatus,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';

// TODO переменные с массивами во множественное число
export interface TripLocalFilter {
  startDate?: Date;
  endDate?: Date;
  departureCity?: string;
  destinationCity?: string;
  transitCity?: string;
  registrationNumber?: string;
  departureLogisticPoint?: LogisticPoint;
  waitingEvent?: TripEventType[];
  destinationLogisticPoint?: LogisticPoint;
  transitLogisticPoint?: LogisticPoint;
  status?: TripStatus[];
  number?: number;
  isRoundTrip?: number;
  carrier?: OptionItem;
  type?: TransportationType[];
  isOwnCarrier?: number[];
  transportationOrderTripName?: string;
  problemType?: ProblemType[];
  tripDriverStatus?: TripDriverStatus[];
}

export enum TripTableAction {
  CREATE_DOCUMENT = 'CREATE_DOCUMENT',
}
