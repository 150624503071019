import { version } from '~/package.json';

import { useAuthStore, useDeviceInfoStore } from '@/shared/config/stores';

import type { RequestInterceptor } from './types';

export const interceptorAuth: RequestInterceptor = {
  onFulfilled: (config) => {
    config.headers['X-Token'] = useAuthStore().accessToken;
    config.headers['X-Fingerprint'] = useDeviceInfoStore().fingerprint;
    config.headers['x-webapp-ver'] = version;
    return config;
  },
};
