import { captureException } from '@sentry/vue';

import { isRefreshError } from '@/shared/lib/utils/check-error.ts';

export const captureExceptionIfRequired = <T>(error: { name?: string }, returnValue: T): T => {
  if (!isRefreshError(error)) {
    captureException(error);
  }
  return returnValue;
};
