import type { MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { canReadDrivers } from '@/entities/session';
import type { Driver, GetDriversParams } from '@/shared/api/generated-api/drivers/data-contracts.ts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { formatName, formatPhoneNumber, formatSnils } from '@/shared/lib/format';
import { createBeforeEnterRouteAccessCheck } from '@/shared/lib/utils';

export const route: RouteRecordRaw = {
  path: 'drivers',
  name: RouteName.DRIVERS,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/Drivers.vue');
  },
  beforeEnter: createBeforeEnterRouteAccessCheck(canReadDrivers),
};

export const defaultFilter: GetDriversParams = {
  driverFio: '',
  driverPhone: '',
  driverSnils: '',
  carrierId: undefined,
};

export const tableColumns: MTableColumn<Driver>[] = [
  {
    label: 'ФИО',
    value: 'fio',
    customRender: formatName,
  },
  {
    label: 'Телефон',
    value: 'primaryPhone',
    customRender: (row): string => formatPhoneNumber(row.primaryPhoneNumber, row.primaryPhoneCountryCode),
  },
  {
    label: 'Доп. Телефон',
    value: 'secondaryPhone',
    customRender: (row): string =>
      row.secondaryPhoneNumber && row.secondaryPhoneCountryCode
        ? formatPhoneNumber(row.secondaryPhoneNumber, row.secondaryPhoneCountryCode)
        : '',
  },
  {
    label: 'СНИЛС',
    value: 'snilsNumber',
    customRender: (row): string => formatSnils(row.snilsNumber),
  },
  {
    label: 'Перевозчик',
    value: 'carrier',
    customRender: (row): string => row.carriers.map(({ companyName }) => companyName).join(', '),
  },
];

export const DRIVERS_PER_PAGE_SIZE = 50;
