import type { MIconColor, MIconNames } from '@mm-frontend/mithril-ui-kit';
import { readableEnum } from '@mm-frontend/mithril-ui-kit';

import { TripDriverStatus } from '@/shared/api/generated-api/transportation-orders/data-contracts';

export const MIN_SEARCH_LENGTH = 3;
export const DRIVER_SELECT_PER_PAGE_SIZE = 10;

export const readableTripDriverStatus = readableEnum<TripDriverStatus, string>({
  [TripDriverStatus.TRIP_DRIVER_STATUS_ASSIGNED]: 'Рейс ожидает подтверждения',
  [TripDriverStatus.TRIP_DRIVER_STATUS_ACCEPTED]: 'Водитель подтвердил рейс',
  [TripDriverStatus.TRIP_DRIVER_STATUS_DECLINED]: 'Водитель отклонил рейс',
});

export const iconFromTripDriverStatus = readableEnum<TripDriverStatus, MIconNames>({
  [TripDriverStatus.TRIP_DRIVER_STATUS_ASSIGNED]: 'person-clock',
  [TripDriverStatus.TRIP_DRIVER_STATUS_ACCEPTED]: 'person-available',
  [TripDriverStatus.TRIP_DRIVER_STATUS_DECLINED]: 'person-delete',
});

export const iconColorFromTripDriverStatus = readableEnum<TripDriverStatus, MIconColor>({
  [TripDriverStatus.TRIP_DRIVER_STATUS_ASSIGNED]: 'caution',
  [TripDriverStatus.TRIP_DRIVER_STATUS_ACCEPTED]: 'positive',
  [TripDriverStatus.TRIP_DRIVER_STATUS_DECLINED]: 'negative',
});
